import { useContext, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { getFullUrl } from 'utils/Url';
import classNames from 'classnames';
import Link from 'next/link';
import { Context } from 'context/GlobalContext';
import LanguageDropdown from 'components/LanguageDropdown';
import { isSimpleMiningUrl } from 'utils/isSimpleMiningUrl';
import dynamic from 'next/dynamic';

const NavigationSignMenu = dynamic(
  () => import('components/Layout/NavigationBar/NavigationSignMenu'),
  {
    ssr: false,
    loading: () => (
      <ul className="sign-menu">
        <li>
          <p className="sign-menu__to-dashboard btn btn-type-white">...</p>
        </li>
      </ul>
    ),
  },
);

interface Props {
  isOpen: boolean;
  setIsOpen: Function;
}

const NavigationBarMenu = (props: Props) => {
  const { isOpen, setIsOpen } = props;

  const { t } = useTranslation();
  const router = useRouter();
  const menuRef = useRef<HTMLDivElement | null>(null);

  const { setIsCurtainActive } = useContext(Context);

  const navLinks = [
    {
      name: 'navigation.barMenuNavLink.home',
      path: '/',
      isVisible: !isSimpleMiningUrl(),
    },
    {
      name: 'navigation.barMenuNavLink.mining',
      path: '/mining',
      isVisible: isSimpleMiningUrl(),
    },
    { name: 'navigation.support', path: '/support', isVisible: true },
  ];

  const classes = classNames('navigation-bar-menu', {
    'navigation-bar-menu--open': isOpen,
  });

  const getLinkClass = path => {
    const basePath = router.asPath.split('#')[0];
    const targetBasePath = path.split('#')[0];

    return classNames('navigation-bar-menu__list-item-link', {
      'navigation-bar-menu__list-item-link--active':
        basePath === targetBasePath,
      active: basePath === targetBasePath,
    });
  };

  const onNavItemClick = () => {
    setIsOpen(false);
    setIsCurtainActive(false);
  };

  const onNavItemEnter = e => {
    const navItems = menuRef?.current?.querySelectorAll(
      '.navigation-bar-menu__list-item-link',
    );

    navItems?.forEach(item => {
      if (item !== e.currentTarget) {
        item.classList.remove('active');
      }
    });
  };

  const onNavItemLeave = e => {
    const navItems = menuRef?.current?.querySelectorAll(
      '.navigation-bar-menu__list-item-link',
    );

    navItems?.forEach(item => {
      if (item.attributes?.[1]?.value === document.location.href) {
        item.classList.add('active');
      }
    });
  };

  return (
    <div ref={menuRef} className={classes}>
      <span className="navigation-bar-menu__title">Menu</span>
      <div className="navigation-bar-menu__list-wrapper">
        <ul className="navigation-bar-menu__list" onMouseLeave={onNavItemLeave}>
          {navLinks.map(
            (link, index) =>
              link.isVisible && (
                <li key={index} className="navigation-bar-menu__list-item">
                  <Link href={getFullUrl(link.path)} passHref legacyBehavior>
                    <a
                      suppressHydrationWarning
                      className={getLinkClass(link.path)}
                      onClick={onNavItemClick}
                      onMouseEnter={onNavItemEnter}
                    >
                      {t(link.name)}
                    </a>
                  </Link>
                </li>
              ),
          )}
        </ul>
        <NavigationSignMenu />
      </div>
      <LanguageDropdown isOpen={isOpen} />
    </div>
  );
};

export default NavigationBarMenu;
